import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "../Sections/style.css";
import "../Services/style.css";
import "./style.css";

import Modal from "../../components/modal";
import { Loader } from "rsuite";
import axios from "axios";
import { toast } from "react-toastify";

function HowWork() {
  const [data, setData] = useState();
  const select_how_i_work = async () => {
    setLoading(true);
    const select = await axios.get(
      "https://camp-coding.tech/alaqra_profile/admin/how_i_work/select_how_i_work.php"
    );
    setData(select?.data?.message);
    setLoading(false);
  };
  useEffect(() => {
    select_how_i_work();
  }, []);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const addskill = async (e) => {
    setLoading(true);
    const data_send = {
      text: e.currentTarget.how_work_title.value,
    };
    const select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/how_i_work/add_how_i_work.php",
      JSON.stringify(data_send)
    );

    if (select?.data?.status && select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_how_i_work();
      setOpenAdd(false);
    } else {
      toast.error(select?.data?.message);
    }

    setLoading(false);
  };

  const editskill = async (e) => {
    setLoading(true);
    console.log(showEdit);
    const data_send = {
      how_i_work_id: showEdit?.item?.id,
      text: e.currentTarget.how_work_title.value,

    };

    const select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/how_i_work/update_how_i_work.php",
      JSON.stringify(data_send)
    );

    console.log(select);
    if (select?.status?.length || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_how_i_work();
      setShowEdit(false);
    } else {
      toast.error(select?.data?.message);
    }

    setLoading(false);
  };

  const deleteskills = async (e) => {
    setLoading(true);
    const data_send = {
      how_i_work_id: openDelete?.item?.id
    };

    const select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/how_i_work/delete_how_i_work.php",
      JSON.stringify(data_send)
    );
    if (select?.status?.length || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_how_i_work();
      setOpenDelete(false);
    } else {
      toast.error(select?.data?.message);
    }

    setLoading(false);
  };
  const [how_work, setHowWorks] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (showMessage) {
      setHowWorks(
        data?.filter((item) => item?.how_work_id == showMessage?.how_work_id)[0]
      );
    }
    setLoading(false);
  }, [showMessage]);

  const [showEdit, setShowEdit] = useState(false);

  const [openAdd, setOpenAdd] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);

  const headers = [
    {
      label: "رقم طريقة العمل",
      dataIndex: "id"
    },
    {
      label: "وصف طريقة العمل",
      dataIndex: "text",
      type: "children",
      children: ({ headers, row }) => {
        return <p className="big_text">{row?.text}</p>;
      }
    },
    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " عرض طريقة العمل  ",
          action: ({ item }) => setShowMessage(item)
        },
        {
          label: " تعديل بيانات طريقة العمل ",
          action: setShowEdit,
          class: "btn-primary"
        },
        {
          label: "حذف طريقة العمل",
          action: setOpenDelete,
          class: "btn-danger"
        }
      ]
    }
  ];

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={"طرق العمل"} />
            <button
              className="btn btn-primary"
              onClick={() => setOpenAdd(true)}
              style={{ margin: "20px 0" }}
            >
              إضافة طريقة عمل
            </button>
            <Table headers={headers} body={data} classess={["table-tc"]} />
            <Modal
              open={showMessage}
              toggle={setShowMessage}
              onClose={setHowWorks}
              headerTitle={"بيانات طريقة العمل"}
              children={
                <>
                  {!loading ? (
                    <div className="section">
                      <div className="id">
                        <span> رقم طريقة العمل : </span>
                        <span>{how_work?.id}</span>
                      </div>
                      <div className="id">
                        <span>وصف طريقة العمل : </span>
                        <span>{how_work?.text}</span>
                      </div>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </>
              }
            />

            <Modal
              open={showEdit}
              toggle={setShowEdit}
              headerTitle={"تعديل بيانات طريقة العمل"}
              children={
                <div className="how_work">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      editskill(e);
                    }}
                  >
                    <div className="inputField">
                      <label htmlFor="how_work_title">عنوان طريقة العمل</label>
                      <input
                        type="text"
                        id="how_work_title"
                        name="how_work_title"
                        defaultValue={showEdit?.item?.text}
                      />
                    </div>
                    <button className="btn btn-success">تعديل</button>
                  </form>
                </div>
              }
            />
            <Modal
              open={openAdd}
              toggle={setOpenAdd}
              headerTitle={"إضافة طريقة عمل جديدة"}
              children={
                <div className="how_work">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      addskill(e);
                    }}
                  >
                     <div className="inputField">
                      <label htmlFor="how_work_title">عنوان طريقة العمل</label>
                      <input
                        type="text"
                        id="how_work_title"
                        name="how_work_title"
                      />
                    </div>
                    <button className="btn btn-success">إضافة</button>
                  </form>
                </div>
              }
            />

            <Modal
              open={openDelete}
              toggle={setOpenDelete}
              headerTitle={"حذف طريقة العمل"}
              children={
                <div className="service">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      deleteskills(e);
                    }}
                  >
                    <h1> هل أنت متأكد من حذف طريقة العمل ؟</h1>
                    <button className="btn btn-danger">حذف</button>
                  </form>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default HowWork;
