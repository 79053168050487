import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";

import "./style.css";
import Modal from "../../components/modal";
import { Loader } from "rsuite";
import axios from "axios";
import { toast } from "react-toastify";

function Sections() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [section, setSection] = useState(false);
  const select_services = async () => {
    const select = await axios.get(
      "https://camp-coding.tech/alaqra_profile/admin/categories_headers/select_categories_headers.php"
    );
    console.log(select?.data);
    setData(select?.data?.message);
  };

  useEffect(() => {
    select_services();
  }, []);
  const [showContactEdit, setShowContactEdit] = useState(false);
  const [showServiceEdit, setShowServiceEdit] = useState(false);
  const [showAboutEdit, setShowAboutEdit] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const editHeader = async (e) => {
    const formData = new FormData(e.currentTarget);
    const images = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/image_uplouder.php",
      formData
    );

    console.log("images", images?.data);
    // console.log("e.currentTarget.section_name", e.target);

    const data_send = {
      name: e.target.section_name.value
        ? e.target.section_name.value
        : showEdit?.item?.name,

      sub_title: e.target.section_description.value
        ? e.target.section_description.value
        : showEdit?.item?.sub_title,

      title: e.target.section_title.value
        ? e.target.section_title.value
        : showEdit?.item?.title,

      image_url: images?.data ? images?.data : showEdit?.item?.image_url
    };

    let select = {};
    if (images?.data) {
      select = await axios.post(
        "https://camp-coding.tech/alaqra_profile/admin/categories_headers/update_header.php",
        JSON.stringify(data_send)
      );
    }
    console.log("select", select);
    if (select?.status == 200 || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_services();
      setShowEdit(false);
    } else {
      toast.error("حدثت مشكلة");
    }

    setLoading(false);
  };

  const editAbout = async (e) => {
    const formData = new FormData(e.currentTarget);
    const images = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/image_uplouder.php",
      formData
    );

    console.log("images", images?.data);

    const data_send = {
      about: e.target.section_description.value
        ? e.target.section_description.value
        : showAboutEdit?.item?.sub_title,

      title: e.target.section_title.value
        ? e.target.section_title.value
        : showAboutEdit?.item?.title,

      image_url: images?.data ? images?.data : showAboutEdit?.item?.image_url
    };

    let select = {};
    if (images?.data) {
      select = await axios.post(
        "https://camp-coding.tech/alaqra_profile/admin/categories_headers/update_about_section.php",
        JSON.stringify(data_send)
      );
    }
    console.log("select", select);
    if (select?.status == 200 || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_services();
      setShowAboutEdit(false);
    } else {
      toast.error("حدثت مشكلة");
    }

    setLoading(false);
  };

  const editService = async (e) => {
    const data_send = {
      details: e.target.section_description.value
        ? e.target.section_description.value
        : showServiceEdit?.item?.details
    };

    let select = {};
    select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/categories_headers/update_header.php",
      JSON.stringify(data_send)
    );

    if (select?.status == 200 || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_services();
      setShowServiceEdit(false);
    } else {
      toast.error("حدثت مشكلة");
    }

    setLoading(false);
  };
  const editContact = async (e) => {
    const data_send = {
      details: e.target.section_description.value
        ? e.target.section_description.value
        : showServiceEdit?.item?.details,

      title: e.target.section_title.value
        ? e.target.section_title.value
        : showAboutEdit?.item?.title
    };

    let select = {};
    select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/categories_headers/update_contact_section.php",
      JSON.stringify(data_send)
    );

    if (select?.status == 200 || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      select_services();
      setShowContactEdit(false);
    } else {
      toast.error("حدثت مشكلة");
    }

    setLoading(false);
  };

  const headers = [
    {
      label: "اسم الشخص",
      dataIndex: "name"
    },
    {
      label: "عنوان القسم",
      dataIndex: "title"
    },
    {
      label: "وصف القسم",
      dataIndex: "about",
      type: "children",
      children: ({ headers, row }) => {
        return <p className="big_text">{row?.sub_title}</p>;
      }
    },
    {
      label: "صورة تعبر عن القسم",
      type: "children",
      children: ({ headers, row }) => {
        return (
          <img
            className="image_url            "
            src={row?.image_url}
            alt=""
            width={"100px"}
          />
        );
      }
    },
    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " تعديل بيانات القسم ",
          action: setShowEdit,
          class: "btn-primary"
        }
      ]
    }
  ];
  const service_headers = [
    {
      label: "وصف القسم",
      dataIndex: "section_description",
      type: "children",
      children: ({ headers, row }) => {
        return <p className="big_text">{row?.details}</p>;
      }
    },

    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " تعديل بيانات القسم ",
          action: setShowServiceEdit,
          class: "btn-primary"
        }
      ]
    }
  ];
  const contact_headers = [
    {
      label: "عنوان القسم",
      dataIndex: "title"
    },
    {
      label: "وصف القسم",
      dataIndex: "details",
      type: "children",
      children: ({ headers, row }) => {
        return <p className="big_text">{row?.details}</p>;
      }
    },
    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " تعديل بيانات القسم ",
          action: setShowContactEdit,
          class: "btn-primary"
        }
      ]
    }
  ];
  const about_headers = [
    {
      label: "عنوان القسم",
      dataIndex: "title"
    },
    {
      label: "وصف القسم",
      dataIndex: "about",
      type: "children",
      children: ({ headers, row }) => {
        return <p className="big_text">{row?.about}</p>;
      }
    },
    {
      label: "صورة تعبر عن القسم",
      type: "children",
      children: ({ headers, row }) => {
        return (
          <img
            className="image_url"
            src={row?.image_url}
            alt=""
            width={"100px"}
          />
        );
      }
    },
    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " تعديل بيانات القسم ",
          action: setShowAboutEdit,
          class: "btn-primary"
        }
      ]
    }
  ];

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={"أقسام الموقع"} />
            <h3>قسم البانر</h3>
            {data?.header ? (
              <Table
                headers={headers}
                body={[data?.header]}
                classess={["table-tc"]}
              />
            ) : null}
            <h3>قسم عن الشخص</h3>
            {data?.about ? (
              <Table
                headers={about_headers}
                body={[data?.about]}
                classess={["table-tc"]}
              />
            ) : null}
            <h3>قسم خدماتي</h3>
            {data?.service_section ? (
              <Table
                headers={service_headers}
                body={[data?.service_section]}
                classess={["table-tc"]}
              />
            ) : null}
            <h3>قسم تواصل معنا</h3>
            {data?.contact_section ? (
              <Table
                headers={contact_headers}
                body={[data?.contact_section]}
                classess={["table-tc"]}
              />
            ) : null}

            <Modal
              open={showEdit}
              toggle={setShowEdit}
              headerTitle={"تعديل بيانات القسم"}
              children={
                <div className="section">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      editHeader(e);
                    }}
                  >
                    <div className="inputField">
                      <label htmlFor="name">اسم الشخص</label>
                      <input
                        type="text"
                        id="name"
                        name="section_name"
                        defaultValue={showEdit?.item?.name}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="section_title">اسم القسم</label>
                      <input
                        type="text"
                        id="section_title"
                        name="section_title"
                        defaultValue={showEdit?.item?.title}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="section_description">وصف القسم</label>
                      <textarea
                        type="text"
                        id="section_description"
                        name="section_description"
                        defaultValue={showEdit?.item?.sub_title}
                        rows={7}
                      />
                    </div>
                    <div className="inputField type_image">
                      <label htmlFor="image">صورة تعبر عن القسم</label>

                      <input type="file" id="image" lang="ar" name="image" />
                    </div>
                    <button className="btn btn-primary">تعديل</button>
                  </form>
                </div>
              }
            />
            <Modal
              open={showContactEdit}
              toggle={setShowContactEdit}
              headerTitle={"تعديل بيانات القسم"}
              children={
                <div className="section">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      editContact(e)
                    }}
                  >
                    <div className="inputField">
                      <label htmlFor="section_title">عنوان القسم</label>
                      <input
                        type="text"
                        id="section_title"
                        name="section_title"
                        defaultValue={showContactEdit?.item?.title}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="section_description">وصف القسم</label>
                      <textarea
                        type="text"
                        id="section_description"
                        name="section_description"
                        defaultValue={showContactEdit?.item?.details}
                        rows={7}
                      />
                    </div>
                 
                    <button className="btn btn-primary">تعديل</button>
                  </form>
                </div>
              }
            />
            <Modal
              open={showServiceEdit}
              toggle={setShowServiceEdit}
              headerTitle={"تعديل بيانات القسم"}
              children={
                <div className="section">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      editService(e);
                    }}
                  >
                    <div className="inputField">
                      <label htmlFor="section_description">وصف القسم</label>
                      <textarea
                        type="text"
                        id="section_description"
                        name="section_description"
                        defaultValue={showServiceEdit?.item?.details}
                        rows={7}
                      />
                    </div>

                    <button className="btn btn-primary">تعديل</button>
                  </form>
                </div>
              }
            />
            <Modal
              open={showAboutEdit}
              toggle={setShowAboutEdit}
              headerTitle={"تعديل بيانات القسم"}
              children={
                <div className="section">
                  <form
                    action=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      editAbout(e);
                    }}
                  >
                    <div className="inputField">
                      <label htmlFor="section_title">اسم القسم</label>
                      <input
                        type="text"
                        id="section_title"
                        name="section_title"
                        defaultValue={showAboutEdit?.item?.title}
                      />
                    </div>
                    <div className="inputField">
                      <label htmlFor="section_description">وصف القسم</label>
                      <textarea
                        type="text"
                        id="section_description"
                        name="section_description"
                        defaultValue={showAboutEdit?.item?.about}
                        rows={7}
                      />
                    </div>
                    <div className="inputField type_image">
                      <label htmlFor="image">صورة تعبر عن القسم</label>

                      <input type="file" id="image" lang="ar" name="image" />
                    </div>
                    <button className="btn btn-primary">تعديل</button>
                  </form>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default Sections;
