import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import Table from "../../components/table";
import "./style.css";
import { Icon } from '@iconify/react';
import phoneBoldDuotone from '@iconify/icons-solar/phone-bold-duotone';
import "./style.css";
import twotoneEmail from '@iconify/icons-ic/twotone-email';

import Modal from "../../components/modal";
import axios from "axios";
import { consultData } from "../../data/consultData";

function WebsiteDatas() {
  const [data, setData] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const select_how_i_work = async () => {
    const select = await axios.get(
      "https://camp-coding.tech/alaqra_profile/admin/form/select_form_msgs.php"
    );
    setData(select?.data?.message);
  };
  useEffect(() => {
    select_how_i_work();
  }, []);

  const headers = [
    {
      label: "رقم التليفون",
      dataIndex: "phone"
    },
    {
      label: " البريد الالكتروني",
      dataIndex: "email"
    },
    {
      label: " المبلغ المراد تقسيطة",
      dataIndex: "budget"
    },
    {
      label: " مصدر دخلك ",
      dataIndex: "sorce_income"
    },
    {
      label: "  دخلك الشهري ",
      dataIndex: "income"
    },
    {
      label: "   الحصول علي تأمين صحي",
      dataIndex: "health_insurance"
    },

    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " عرض الرسالة  ",
          action: setShowMessage
        }
      ]
    }
  ];
  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={" الأشخاص"} />
            <Table headers={headers} body={data} classess={["table-tc"]} />
            <Modal
              open={showMessage}
              toggle={setShowMessage}
              headerTitle={"الرسالة رقم " + showMessage?.item?.msg_id}
              children={
                <div className="message_details">
                  {" "}
                  <p>
                    <span>اسم العميل:</span>
                    <pre>{showMessage?.item?.name}</pre>
                  </p>
                  <p>
                    <span>رقم تليفون العميل:</span>
                    <pre>
                      <span>{showMessage?.item?.phone}</span>
                      <a
                        href={"tel:" + showMessage?.item?.phone}
                        className="btn"
                      >
                        <Icon icon={phoneBoldDuotone} />
                      </a>
                    </pre>
                  </p>
                  <p>
                    <span>البريد الالكتروني الخاص بالعميل:</span>
                    <pre>
                      <span>{showMessage?.item?.email}</span>{" "}
                      <a
                        href={"mailto:" + showMessage?.item?.email}
                        className="btn"
                      >
                        <Icon icon={twotoneEmail} />
                      </a>
                    </pre>
                  </p>
                  <p>
                    <span>وقت الإرسال</span>{" "}
                    <pre>{showMessage?.item?.time}</pre>
                  </p>
                  <p>
                    <span>اسم المتجر (المشروع)</span>{" "}
                    <pre>{showMessage?.item?.project_name}</pre>
                  </p>
                  <p>
                    <span>رابط المتجر (المشروع)</span>{" "}
                    <a
                      className="btn btn-primary"
                      target="_blank"
                      href={
                        showMessage?.item?.project_url.startsWith("http")
                          ? showMessage?.item?.project_url
                          : "https://" + showMessage?.item?.project_url
                      }
                    >
                      زيارة
                    </a>
                  </p>
                  <p>
                    <span> الخدمة </span>{" "}
                    <pre>{showMessage?.item?.service_type}</pre>
                  </p>
                  {console.log(showMessage)}
                  <p className="message">
                    <span> التفاصيل</span>
                    <pre>{showMessage?.item?.description}</pre>
                  </p>
                </div>
              }
            />
          </div>
        }
      />
    </div>
  );
}

export default WebsiteDatas;
