import React, { useEffect, useState } from "react";

import Table from "../../../components/table";
import "../../Sections/style.css";
import "../../Services/style.css";

import Modal from "../../../components/modal";
import { Loader } from "rsuite";
import axios from "axios";
import { toast } from "react-toastify";

function Skills() {
  const [data, setData] = useState();
  const selectskill = async () => {
    setLoading(true);

    const select = await axios.get(
      "https://camp-coding.tech/alaqra_profile/admin/skills/select_skills.php"
    );

    setData(select?.data?.message);
    setLoading(false);
  };
  useEffect(() => {
    selectskill();
  }, []);
  const addskill = async (e) => {
    setLoading(true);
    const data_send = {
      value: e.currentTarget.skill_rate.value,
      title: e.currentTarget.skill_title.value
    };
    const select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/skills/add_skills.php",
      JSON.stringify(data_send)
    );

    if (select?.data?.status && select?.data?.status == "success") {
      toast.success(select?.data?.message);
      selectskill();
      setOpenAdd(false);
    } else {
      toast.error(select?.data?.message);
    }

    setLoading(false);
  };

  const editskill = async (e) => {
    setLoading(true);
    console.log(showEdit);
    const data_send = {
      skills_id: showEdit?.item?.id,
      value: e.currentTarget.skill_rate.value
        ? e.currentTarget.skill_rate.value
        : showEdit?.item?.value,

      title: e.currentTarget.skill_title.value
        ? e.currentTarget.skill_title.value
        : showEdit?.item?.title
    };

    const select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/skills/update_skills.php",
      JSON.stringify(data_send)
    );

    console.log(select);
    if (select?.status?.length || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      selectskill();
      setShowEdit(false);
    } else {
      toast.error(select?.data?.message);
    }

    setLoading(false);
  };

  const deleteskills = async (e) => {
    setLoading(true);
    const data_send = {
      skills_id: openDelete?.item?.id
    };

    console.log(data_send)
    const select = await axios.post(
      "https://camp-coding.tech/alaqra_profile/admin/skills/delete_skills.php",
      JSON.stringify(data_send)
    );

    console.log(select);
    if (select?.status?.length || select?.data?.status == "success") {
      toast.success(select?.data?.message);
      selectskill();
      setOpenDelete(false);
    } else {
      toast.error(select?.data?.message);
    }

    setLoading(false);
  };
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [skill, setskills] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (showMessage) {
      setskills(
        data?.filter((item) => item?.skill_id == showMessage?.skill_id)[0]
      );
    }
    setLoading(false);
  }, [showMessage]);

  const [showEdit, setShowEdit] = useState(false);
  const editskills = (e) => {};

  const [openAdd, setOpenAdd] = useState(false);
  const addskills = (e) => {};
  const [openDelete, setOpenDelete] = useState(false);

  const headers = [
    {
      label: "عنوان المهارة",
      dataIndex: "title"
    },
    {
      label: "نسبة التمكن",
      dataIndex: "value"
    },
    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " عرض ",
          action: ({ item }) => setShowMessage(item)
        },
        {
          label: " تعديل بيانات ",
          action: setShowEdit,
          class: "btn-primary"
        },
        {
          label: "حذف",
          action: setOpenDelete,
          class: "btn-danger"
        }
      ]
    }
  ];

  return (
    <div className="childs">
      <button
        className="btn btn-primary"
        onClick={() => setOpenAdd(true)}
        style={{ margin: "20px 0" }}
      >
        إضافة مهارة
      </button>
      <Table headers={headers} body={data} classess={["table-tc"]} />
      <Modal
        open={showMessage}
        toggle={setShowMessage}
        onClose={setskills}
        headerTitle={"المهارات"}
        children={
          <>
            {!loading ? (
              <div className="section">
                <div className="id">
                  <span> عنوان مهارة : </span>
                  <span>{skill?.skill_title}</span>
                </div>
                <div className="id">
                  <span> نسبة التمكن : </span>
                  <span>{skill?.skill_rate}</span>
                </div>
              </div>
            ) : (
              <Loader />
            )}
          </>
        }
      />

      <Modal
        open={showEdit}
        toggle={setShowEdit}
        headerTitle={"تعديل  "}
        children={
          <div className="skill">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                editskill(e);
              }}
            >
              <div className="inputField">
                <label htmlFor="skill_title">عنوان المهارة</label>
                <input
                  type="text"
                  id="skill_title"
                  name="skill_title"
                  defaultValue={showEdit?.item?.title}
                />
              </div>
              <div className="inputField">
                <label htmlFor="skill_rate">نسبة التمكن</label>
                <input
                  type="number"
                  min={0}
                  max={100}
                  id="skill_rate"
                  name="skill_rate"
                  defaultValue={showEdit?.item?.value}
                />
              </div>
              <button className="btn btn-success">تعديل</button>
            </form>
          </div>
        }
      />
      <Modal
        open={openAdd}
        toggle={setOpenAdd}
        headerTitle={"إضافة مهارة جديدة"}
        children={
          <div className="skill">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                addskill(e);
              }}
            >
              <div className="inputField">
                <label htmlFor="skill_title">عنوان المهارة</label>
                <input
                  type="text"
                  id="skill_title"
                  name="skill_title"
                  defaultValue={showEdit?.item?.skill_title}
                />
              </div>
              <div className="inputField">
                <label htmlFor="skill_rate">نسبة التمكن</label>
                <input
                  type="number"
                  min={0}
                  max={100}
                  id="skill_rate"
                  name="skill_rate"
                  defaultValue={showEdit?.item?.skill_rate}
                />
              </div>

              <button className="btn btn-success">تعديل</button>
            </form>
          </div>
        }
      />

      <Modal
        open={openDelete}
        toggle={setOpenDelete}
        headerTitle={"حذف مهارة"}
        children={
          <div className="service">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                deleteskills(e);
              }}
            >
              <h1> هل أنت متأكد من حذف مهارة ؟</h1>
              <button className="btn btn-danger">حذف</button>
            </form>
          </div>
        }
      />
    </div>
  );
}

export default Skills;
