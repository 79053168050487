import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import {
  LoggedIn,
  fakeCredintials,
  loggedInData,
} from "../../data/fakeLoginData";

function Login() {
  const navigate = useNavigate();

  const [loginLoading, setLoginLoading] = useState(false);
  const login = (e) => {
    setLoginLoading(true);
    const email = e.currentTarget.email.value;
    const password = e.currentTarget.password.value;
    axios
      .post("https://baseeta-form.com/basita/login_agent.php", {
        agent_login_id: email,
        password,
      })
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          toast.success("تم تسجيل الدخول بنجاح");
          localStorage.setItem("logAuth", JSON.stringify(res.data.message));
          setTimeout(() => {
            if (JSON.parse(localStorage.getItem("logAuth"))) {
              window.location.href = "/Message_History";
            }
          }, 1000);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };
  useEffect(() => {
    if (loggedInData) navigate("/Message_History");
  }, []);
  return (
    <Fragment>
      <div className="authContainer">
        <div className="authFunction">
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
              login(e);
            }}
          >
            <div className="inputField">
              <label htmlFor="email">كود تسجيل الدخول</label>
              <input type="text" id="email" name="email" />
            </div>
            <div className="inputField">
              <label htmlFor="password">كلمة السر</label>
              <input type="password" id="password" name="password" />
            </div>

            {!loginLoading ? (
              <button className="btn btn-success">تسجيل الدخول</button>
            ) : null}
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
