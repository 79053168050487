import React, { useState } from "react";
import "./style.css";
import { Icon } from "@iconify/react";
import { logOut } from "./functions";
import { useSelector } from "react-redux";
function ProfileMenu({ userData }) {
  const language = useSelector((state) => state.language);
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <div className="profileMenu">
      {/* <div className="top_info" onClick={() => setOpenMenu(!openMenu)}>
        <span>
          {!openMenu ? (
            <Icon icon="ei:chevron-down" />
          ) : (
            <Icon icon="ei:chevron-up" />
          )}
        </span>

        <span>{userData?.user_name}</span>
        <span className="imgSpan">
          {userData
            ? Object.keys(userData).length
              ? userData?.user_name
                ? userData?.user_name[0]?.toUpperCase()
                : "A"
              : "A"
            : "A"}
        </span>
      </div> */}

      <div className="menu-down">
        <button className="btn btn-success" onClick={() => logOut()}>
          {language == "ar" ? "تسجيل الخروج" : "Log Out"}
        </button>
      </div>
    </div>
  );
}

export default ProfileMenu;
