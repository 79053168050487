export const fakeCredintials = [
  {
    name: "بسيطة",
    jop: "دكتور",
    specialty: "اخصائي علاج العظام",
    image:
      "https://res.cloudinary.com/duovxefh6/image/upload/v1693605788/man_lpskqh.png",
    email: "baseta@gmail.com",
    password: "1231",
    type: "admin",
    address: "طنطا ",
    age: "24",
    phone:"00002222"
  }
];

export const loggedInData = localStorage.getItem("logAuth")
  ? JSON.parse(localStorage.getItem("logAuth"))
  : null;

export const LoggedIn = loggedInData