import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layout/defaultlayout";
import ContentNav from "../../../datanavcontent";
import Table from "../../../components/table";

import Modal from "../../../components/modal";

function Person() {
  const [data, setData] = useState([
    {
      person_id: "1",
      person_name: "محمد الأقرع",
      person_description: "بل هو رحلة تتطلب خطوات استراتيجية دقيقة",
      person_phone: "01212745939",
      person_email: "mmoh33650@gmail.com",
      person_location: "Cairo - Egypt",
      person_image:
        "https://res.cloudinary.com/duovxefh6/image/upload/v1693610423/download_rxh40r.png",
    },
  ]);

  const [showMessage, setShowMessage] = useState(false);
  const [person, setWebsiteData] = useState(false);
  useEffect(() => {
    setWebsiteData(data?.filter((item) => item.id == showMessage?.item?.id)[0]);
  }, [showMessage]);

  const [showEdit, setShowEdit] = useState(false);

  const headers = [
    {
      label: "صورة الشخص",
      type: "children",
      children: ({ headers, row }) => {
        return (
          <img
            className="person_image"
            src={row?.person_image}
            alt=""
            width={"100px"}
          />
        );
      },
    },
    {
      label: "اسم الشخص",
      dataIndex: "person_name",
    },
    {
      label: "معلومات عن الشخص",
      dataIndex: "person_description",
    },
    {
      label: "رقم تليفون الشخص",
      dataIndex: "person_phone",
    },
    {
      label: "إيميل الشخص",
      dataIndex: "person_email",
    },
    {
      label: "عنوان الشخص",
      dataIndex: "person_location",
    },

    {
      label: "أوامر",
      type: "actions",
      actions: [
        {
          label: " عرض بيانات الشخص  ",
          action: setShowMessage,
        },
        {
          label: " تعديل بيانات الشخص ",
          action: setShowEdit,
          class: "btn-primary",
        },
      ],
    },
  ];
  const editWebsiteData = (e) => {
    const uploadedImage = false;
  };
  return (
    <div className="childs">
      <Table headers={headers} body={data} classess={["table-tc"]} />
      <Modal
        open={showMessage && person}
        toggle={setShowMessage}
        headerTitle={"بيانات الشخص"}
        children={
          <div
            className="person"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div className="id">
              <span> اسم الشخص : </span>
              <span>{person?.person_name}</span>
            </div>
            <div className="id">
              <span>معلومات عن الشخص : </span>
              <span>{person?.person_description}</span>
            </div>
            <div className="id">
              <span> إيميل الشخص : </span>
              <span>{person?.person_email}</span>
            </div>
            <div className="id">
              <span> رقم تليفون الشخص : </span>
              <span>{person?.person_phone}</span>
            </div>
            <div className="id">
              <span>عنوان الشخص : </span>
              <span>{person?.person_location}</span>
            </div>
            <div className="id" style={{ margin: "auto" }}>
              <img src={person?.person_image} alt="" width={"140px"} />
            </div>
          </div>
        }
      />

      <Modal
        open={showEdit}
        toggle={setShowEdit}
        headerTitle={"تعديل بيانات الشخص"}
        children={
          <div className="person">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                editWebsiteData(e);
              }}
              style={{ width: "100%" }}
            >
              <div className="inputField">
                <label htmlFor="person_name">اسم الشخص</label>
                <input
                  type="text"
                  id="person_name"
                  name="person_name"
                  defaultValue={showEdit?.item?.person_name}
                />
              </div>
              <div className="inputField">
                <label htmlFor="person_location">عنوان الشخص</label>
                <input
                  type="text"
                  id="person_location"
                  name="person_location"
                  defaultValue={showEdit?.item?.person_location}
                />
              </div>
              <div className="inputField">
                <label htmlFor="person_email">إيميل الشخص</label>
                <input
                  type="text"
                  id="person_email"
                  name="person_email"
                  defaultValue={showEdit?.item?.person_email}
                />
              </div>
              <div className="inputField">
                <label htmlFor="person_phone">رقم تليفون الشخص</label>
                <input
                  type="text"
                  id="person_phone"
                  name="person_phone"
                  defaultValue={showEdit?.item?.person_phone}
                />
              </div>
              <div className="inputField">
                <label htmlFor="person_description">معلومات عن الشخص</label>
                <textarea
                  type="text"
                  id="person_description"
                  name="person_description"
                  defaultValue={showEdit?.item?.person_description}
                  rows={7}
                />
              </div>
              <div className="inputField type_image">
                <label htmlFor="person_image">تغيير صورة الشخص</label>

                <input
                  type="file"
                  id="person_image"
                  lang="ar"
                  name="person_image"
                />
              </div>
              <button className="btn btn-primary">تعديل</button>
            </form>
          </div>
        }
      />
    </div>
  );
}

export default Person;
