import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import { loggedInData } from "../../data/fakeLoginData";
import "./style.css";
import { Grid } from "@mui/material";
import Modal from "../../components/modal";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { imageURL } from "./image";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { QRCodeCanvas, QRCodeSVG } from "qrcode.react";
import QRCode from "qrcode";
import { toast } from "react-toastify";
import axios from "axios";
import { Loader } from "rsuite";
import 'rsuite/dist/rsuite.css';

function Message_History() {
  const language = useSelector((state) => state.language);
  const [openDelete, setOpenDelete] = useState(false);
  const [color, setColor] = useState("white");
  const [message, setMessage] = useState(null);
  const [sendMessage, setSendMessage] = useState(false);
  const [backGroundColor, setBackGroundColor] = useState("black");
  const [text, setText] = useState("تضمين");
  const [qrCode, setQrCode] = useState(null);
  const [input, setInput] = useState(
    `<button onclick="() => window.open("https://baseeta-form.com/?q=${loggedInData?.login_id}", "_blanck")" style="color: ${color}, background: ${backGroundColor}">${text}</button>`
  );
  useEffect(() => {
    generateQrCode();
  }, []);

  const generateQrCode = async () => {
    const qr = await QRCode.toDataURL(
      `https://baseeta-form.com/?q=${loggedInData?.login_id}`
    );
    setQrCode(qr);
  };
  const [sendLoading, setsendLoading] = useState(false);
  const sendMessages = async () => {
    if (!message && !message?.length)
      return toast.error("أدخل رقم التليفون المراد إرسال رساله له");
    setsendLoading(true);

    await axios
      .post(
        `https://app.wawp.net/api/send`,
        JSON.stringify({
          number: "2" + message,
          type: "link",
          enable: true,
          message: `https://baseeta-form.com/?q=${loggedInData?.login_id}`,
          instance_id: "65D0CE96DD7D7",
          access_token: "65c9fab0ae3be",
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success("تم الإرسال بنجاح");
          setSendMessage(false);
          setMessage(false);
        } else {
          toast.error("حدث خطأ أثناء إرسال الرسالة");
        }
      })
      .catch((err) => err)
      .finally(() => setsendLoading(false));
  };
  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <div className="header">
              <ContentNav
                head={language == "ar" ? "الملف الشخصي" : "Profile"}
              />
              <div
                className="rowDiv"
                style={{ alignItems: "center", gap: "10px" }}
              >
                <button
                  style={{ marginLeft: "10px", marginRight: "10px" }}
                  className="btn btn-primary"
                  onClick={() => setOpenDelete(true)}
                >
                  {" "}
                  {language == "ar"
                    ? "استخدام بسيطة في موقعك"
                    : "Put button on your website"}
                </button>
                <button
                  className="btn btn-success"
                  onClick={() => setSendMessage(true)}
                >
                  {" "}
                  {language == "ar" ? "إرسال رسالة" : "Send Message"}
                </button>
              </div>
            </div>
            <Modal
              open={openDelete}
              toggle={setOpenDelete}
              headerTitle={
                language == "ar"
                  ? "استخدام بسيطة في موقعك"
                  : "Put button on your website"
              }
              children={
                <div>
                  <div className="btnsContainer">
                    <a
                      onClick={() =>
                        window.open(
                          `https://baseeta-form.com/?q=${loggedInData?.login_id}`,
                          "_blanck"
                        )
                      }
                      style={{
                        margin: "10px auto",
                        // color: color,
                        // background: backGroundColor,
                        width: "fit-content",
                        padding: "20px",
                        borderRadius: "10px",
                        justifyContent: "center",
                        display: "flex",
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                    >
                      {" "}
                      <img
                        style={{ width: "220px" }}
                        src="https://res.cloudinary.com/duovxefh6/image/upload/v1701920694/bas_btn_qdss9u.png"
                      />
                    </a>
                  </div>
                  <div className="codeContainer">
                    <div className="copyBtn">
                      <CopyToClipboard
                        onCopy={() =>
                          toast.success(
                            language == "ar" ? "تم النسخ" : "Copied"
                          )
                        }
                        text={`<a href="https://baseeta-form.com/?q=${loggedInData?.login_id}" target="_blanck" style="
    box-sizing: border-box;
    position: relative;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
    direction: ltr !important;
    text-align: left !important;
    color: white;
    background: hsl(192deg 100% 22.55%);
    width: fit-content;
    padding: 20px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
    margin: auto;
    overflow: hidden;
    text-decoration: none;
    color:white !important;
">
   <img style= "width: 220px" src="https://res.cloudinary.com/duovxefh6/image/upload/v1701920694/bas_btn_qdss9u.png" />
</a>`}
                      >
                        <span className="">
                          {" "}
                          {language == "ar" ? "نسخ" : "Copy"}{" "}
                        </span>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              }
            />
            <Modal
              open={sendMessage}
              toggle={setSendMessage}
              headerTitle={language == "ar" ? "إرسال رسالة" : "Send Message"}
              children={
                <div>
                  <div className="inputField">
                    <label htmlFor="">
                      {language == "ar" ? "رقم التليفون " : "Phone Number"}
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  {!sendLoading ? (
                    <button
                      className="btn btn-success"
                      onClick={() => sendMessages()}
                    >
                      {language == "ar" ? "إرسال " : "Send "}
                    </button>
                  ) : (
                    <Loader size="md" />
                  )}
                </div>
              }
            />
            <div className="Contan">
              <div className="main">
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <div className="image">
                      <img src={loggedInData?.image} alt="" />
                    </div>
                  </Grid>
                  <Grid item xs={7}>
                    <div className="content">
                      <div className="C_title">
                        <h1>{loggedInData?.name}</h1>
                        <h5>{loggedInData?.specialty}</h5>
                      </div>
                      <div className="qrContainer">
                        <a href={qrCode} className="" download>
                          <img src={qrCode} alt="" />
                        </a>
                      </div>
                      <Link
                        className="linkk"
                        to={`https://baseeta-form.com/basita/pdf_data_generated/paseeta_pdf/eplus_pdp.php?code=${loggedInData?.login_id}`}
                        target="_blanck"
                      >
                        {language != "ar" ? "Print Qr" : "طباعة ال Qr"}
                      </Link>
                      <div className="info">
                        {loggedInData.age ? (
                          <div className="item">
                            <span
                              style={{
                                color: "black",
                                fontSize: "20",
                                lineHeight: "25px",
                                fontWeight: "bold",
                                width: "60px",
                              }}
                            >
                              العمر
                            </span>
                            <span
                              style={{
                                color: "gray",
                                fontSize: "20",
                                lineHeight: "25px",
                                fontWeight: "bold",
                                textAlign: "right",
                                marginRight: "40px",
                              }}
                            >
                              {loggedInData.age}
                            </span>
                          </div>
                        ) : null}
                        {loggedInData.address ? (
                          <div className="item">
                            <span
                              style={{
                                color: "black",
                                fontSize: "20",
                                lineHeight: "25px",
                                fontWeight: "bold",
                                width: "60px",
                              }}
                            >
                              العنوان
                            </span>
                            <span
                              style={{
                                color: "gray",
                                fontSize: "20",
                                lineHeight: "25px",
                                fontWeight: "bold",
                                textAlign: "right",
                                marginRight: "40px",
                              }}
                            >
                              {loggedInData.address}
                            </span>
                          </div>
                        ) : null}
                        {loggedInData.email ? (
                          <div className="item">
                            <span
                              style={{
                                color: "black",
                                fontSize: "20",
                                lineHeight: "25px",
                                fontWeight: "bold",
                                width: "60px",
                              }}
                            >
                              الايميل
                            </span>
                            <span
                              style={{
                                color: "gray",
                                fontSize: "20",
                                lineHeight: "25px",
                                fontWeight: "bold",
                                textAlign: "right",
                                marginRight: "40px",
                              }}
                            >
                              {loggedInData?.email}
                            </span>
                          </div>
                        ) : null}

                        {loggedInData.specialty ? (
                          <div className="item">
                            <span
                              style={{
                                color: "black",
                                fontSize: "20",
                                lineHeight: "25px",
                                fontWeight: "bold",
                                width: "60px",
                              }}
                            >
                              التخصص
                            </span>
                            <span
                              style={{
                                color: "gray",
                                fontSize: "20",
                                lineHeight: "25px",
                                fontWeight: "bold",
                                textAlign: "right",
                                marginRight: "40px",
                              }}
                            >
                              {loggedInData.specialty}
                            </span>
                          </div>
                        ) : null}
                        {loggedInData.phone ? (
                          <div className="item">
                            <span
                              style={{
                                color: "black",
                                fontSize: "20",
                                lineHeight: "25px",
                                fontWeight: "bold",
                                width: "60px",
                              }}
                            >
                              {language == "ar" ? "الهاتف" : "Mobile"}
                            </span>
                            <span
                              style={{
                                color: "gray",
                                fontSize: "20",
                                lineHeight: "25px",
                                fontWeight: "bold",
                                textAlign: "right",
                                marginRight: "40px",
                              }}
                            >
                              {loggedInData.phone}
                            </span>
                          </div>
                        ) : null}
                        <div className="codeContainer">
                          <div className="copyBtn">
                            <CopyToClipboard
                              onCopy={() =>
                                toast.success(
                                  language == "ar" ? "تم النسخ" : "Copied"
                                )
                              }
                              text={
                                "https://baseeta-form.com/?q=" +
                                loggedInData?.login_id
                              }
                            >
                              <div className="C_title">
                                {" "}
                                <input
                                  type="text"
                                  value={
                                    "https://baseeta-form.com/?q=" +
                                    loggedInData?.login_id
                                  }
                                  disabled
                                />
                                <span className="">
                                  {" "}
                                  {language == "ar" ? "نسخ" : "Copy"}{" "}
                                </span>
                              </div>
                            </CopyToClipboard>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
export default Message_History;
